<template>
  <master-layout bgColor="white">

    <h1>Elfelejtett jelszó</h1>
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-label position="stacked">E-mail</ion-label>
          <ion-input v-model="userInfo.email"></ion-input>
        </ion-item>
        <div class="error-message ion-padding" data-testid="message-area">
          {{ errorMessage }}
        </div>
      </ion-card-content>
    </ion-card>
    <ion-button expand="full" shape="round" @click="send()">Jelszó visszaállítása</ion-button>
    <ion-button expand="block" fill="transparent" color="primary" @click="$router.go(-1)">Mégse</ion-button>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonItem, IonLabel, IonInput
} from '@ionic/vue'
import axios from 'axios'

export default {
  components: {
    IonCard, IonCardContent, IonItem, IonLabel, IonInput
  },
  data() {
    return {
      userInfo: {
        email: ''
      },
      errorMessage: ''
    }
  },
  methods: {
    async send() {
      if (this.userInfo.email) {
        try {
          await axios.post(
            process.env.VUE_APP_STRAPI_URI + 'auth/forgot-password',
            {
              email: this.userInfo.email
            },{
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          this.errorMessage = ''
          alert('A jelszó visszaállítással kapcsolatos e-mailt elküldtük számodra.')
          this.$router.push('/app/udvozoljuk')
        } catch (error) {
          this.errorMessage = 'Biztos, hogy ezt az e-mail használtad a kontódhoz?'
          console.log('An error occurred:', error.response.data.error)
        }
      } else {
        this.errorMessage = 'Kérjük, töltsd ki az összes mezőt!'
      }
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 20px 40px;
}
.pMargins{
  margin: 20px 0 10px 0;
}
</style>
